/* eslint-disable jsx-a11y/anchor-is-valid 
/* eslint-disable jsx-a11y/anchor-has-content 
/* eslint-disable jsx-a11y/anchor-is-valid 
/* eslint-disable jsx-a11y/anchor-has-content 
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';
import { withPrefix } from 'gatsby';
import Script from 'react-load-script';
import IFrame from 'react-iframe';
// import { GAPageView } from '@ic-hosting/ich-react-redux-ga'

import TopBar from '../TopBar';
import Footer from '../Footer';
import theme from '../../utils/theme';


const layoutName = "1JiktKjiPZyxSZGAyDUWS8";

const ls = (key) => {
  if (typeof window !== `undefined`) {
    return window ? JSON.parse(window.localStorage.getItem(key)) : false
  }
  return false
}


class LayoutTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scriptsLoaded: {},
      ...props.state,
    };

    this.loadedScript = this.loadedScript.bind(this);
    this.isAllLoaded = this.isAllLoaded.bind(this);
  }

  componentWillMount() {
    //const { firePageLoadEvent } = this.props;
    //firePageLoadEvent();
  }
  /* eslint-disable */
  loadScript(src, async, defered) {
    if (typeof document !== 'undefined') {
      const script = document.createElement('script');

      script.src = src;
      script.async = async;
      script.defer = defered;

      document.body.appendChild(script);
    }
  }
  /* eslint-enable */


  loadedScript(scriptName) {
    return () => {
      const { scriptsLoaded } = this.state;
      scriptsLoaded[scriptName] = true;
      this.setState({ scriptsLoaded });
      if (scriptName === 'main') {
        const {runAllMain} = this.props;
        // eslint-disable-next-line no-undef
        global.loadMain(jQuery, runAllMain);
      }
    };
  }

  isAllLoaded() {
    const { scriptsLoaded } = this.state;
    return scriptsLoaded.jqueryui
      && scriptsLoaded.bootstrap
      && scriptsLoaded.jqueryplugincollection;
  }

  isJQueryLoaded() {
    const { scriptsLoaded } = this.state;
    return scriptsLoaded.jquery;
  }

  isJQueryLoadedUI() {
    const { scriptsLoaded } = this.state;
    return scriptsLoaded.jqueryui;
  }

  isBootstrapLoaded() {
    const { scriptsLoaded } = this.state;
    return scriptsLoaded.bootstrap;
    }

  isReload() {
    const { scriptsLoaded } = this.state;
    return scriptsLoaded.main;
  }

  innerComponent(props) {
    const { children, content } = props;

    const { layouts } = content.data;
    return (
        <div>
          <Script
            url={withPrefix('js/jquery-2.2.4.min.js')}
            onLoad={this.loadedScript('jquery')}
          />
          {this.isJQueryLoaded() ? (
            <div>
              <Script
                url={withPrefix('js/jquery-ui.min.js')}
                onLoad={this.loadedScript('jqueryui')}
              />
            </div>
          ) : null }
          {this.isJQueryLoadedUI() ? (
            <div>
              <Script
                url={withPrefix('js/bootstrap.min.js')}
                onLoad={this.loadedScript('bootstrap')}
              />
            </div>
          ) : null }
          {this.isBootstrapLoaded() ? (
            <div>
              <Script
                url={withPrefix('js/jquery-plugin-collection.js')}
                onLoad={this.loadedScript('jqueryplugincollection')}
              />
            </div>
            ) : null }
          {this.isAllLoaded() || this.isReload() ? (
            <Script
              url={withPrefix('js/custom.js?version=1.0.1')}
              onLoad={this.loadedScript('main')}
            />
          ) : null }
          <Helmet
            titleTemplate={`%s | ${layouts[layoutName].siteTitle}`}
            defaultTitle={layouts[layoutName].siteTitle}
            meta={[
              { name: 'description', content: layouts[layoutName].siteDescription },
              { name: 'keywords', content: layouts[layoutName].keywords },
            ]}
          >
            <link rel="canonical" href="http://rhee-tkd.net" />
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="author" content="IC-Hosting Pty Ltd" />
            <link
              rel="shortcut icon"
              href={layouts[layoutName].logo.size({
                width: 32,
                height: 32,
              })}
            />

            {/* Add to homescreen for Chrome on Android. Details: http://bit.ly/1otSLve */}
            <meta name="mobile-web-app-capable" content="yes" />
            <link
              rel="icon"
              sizes="196x196"
              href={layouts[layoutName].logo.size({
                width: 196,
                height: 196,
              })}
            />

            {/*  Tile icon for Win8 (144x144 + tile color). Details: http://bit.ly/1Uf14P4 */}
            <meta
              name="msapplication-TileImage"
              content={layouts[layoutName].logo.size({
                width: 144,
                height: 144,
              })}
            />
            <meta name="msapplication-TileColor" content="#202429" />

            {/* Add to homescreen for Safari on iOS. Details: http://apple.co/1Uf0FMJ */}
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="black" />
            <meta name="apple-mobile-web-app-title" content="rhee tkd" />

            {/* Apple touch icons. Details: http://apple.co/1Uf0FMJ */}
            <link
              rel="apple-touch-icon"
              href={layouts[layoutName].logo.size({
                width: 57,
                height: 57,
              })}
            />
            <link
              rel="apple-touch-icon"
              sizes="76x76"
              href={layouts[layoutName].logo.size({
                width: 76,
                height: 76,
              })}
            />
            <link
              rel="apple-touch-icon"
              sizes="120x120"
              href={layouts[layoutName].logo.size({
                width: 120,
                height: 120,
              })}
            />
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href={layouts[layoutName].logo.size({
                width: 152,
                height: 152,
              })}
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={layouts[layoutName].logo.size({
                width: 180,
                height: 180,
              })}
            />

            <link href="//fonts.googleapis.com/css?family=Lato:300,400,700%7CMontserrat:400,700%7CRaleway:300,400,600" rel="stylesheet" />

            <link rel="stylesheet" href="/css/bootstrap.min.css?version=1.0.1" />
            <link rel="stylesheet" href="/css/jquery-ui.min.css?version=1.0.1" />
            <link rel="stylesheet" href="/css/animate.css?version=1.0.1" />
            <link rel="stylesheet" href="/css/css-plugin-collections.css?version=1.0.1" />
            <link rel="stylesheet" href="//use.fontawesome.com/releases/v5.5.0/css/all.css" integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU" crossOrigin="anonymous" />

            <link id="menuzord-menu-skins" href="/css/menuzord-skins/menuzord-rounded-boxed.css?version1.0.0" rel="stylesheet" />
            <link href="/css/style-main.css?version=1.0.0" rel="stylesheet" type="text/css" />
            {/* <link href="/css/style-main-dark.css?version=1.0.0" rel="stylesheet" type="text/css" /> */}
            <link href="/css/preloader.css?version=1.0.0" rel="stylesheet" type="text/css" />
            <link href="/css/custom-bootstrap-margin-padding.css?version=1.0.0" rel="stylesheet" type="text/css" />
            <link href="/css/responsive.css?version=1.0.0" rel="stylesheet" type="text/css" />
            <link href="/css/style.css?version=1.0.0" rel="stylesheet" type="text/css" />
            <link href="/css/colors/theme-skin-yellow.css?version=1.0.0" rel="stylesheet" type="text/css" />


            {/* <!-- ============================================= --> */}
          </Helmet>
          <div id="wrapper" className="clearfix">
            {/* preloader */}
            <div id="preloader">
              <div id="spinner">
                <img src="/images/preloaders/1.gif" alt="" />
              </div>
              <div id="disable-preloader" className="btn btn-default btn-sm">Disable Preloader</div>
            </div>

            <TopBar />
            <a name="home" />
            <div className="main-content">
              {children}
            </div>
            <Footer content={content}/>
            <div className="check-media" />
            <a className="scrollToTop" href="#">
              <i className="fa fa-angle-up"></i>
            </a>
          </div>
          {/*  Custom javascript code */}
          {/*  <script src="js/custom.js"></script> */}
        </div>
    );
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        {this.innerComponent(this.props)}
      </ThemeProvider>
    );
  }
}

// const firePageLoad = dispatch => () => dispatch(GAPageView({ type: 'GA_PAGE_LOAD' }));

const mapStateToProps = state => ({ state, content: state.contentful });


const mapDispatchToProps = dispatch => ({
  // firePageLoadEvent: firePageLoad(dispatch),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutTemplate);
