/* eslint-disable react/self-closing-comp */
import React from 'react';

import CMSWrapper from '@ic-hosting/ich-contentful-cms-wrapper';
// import CMSWrapper, { ImageEditor, TypeEditor } from'../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';
// import ContactForm from '@ic-hosting/ich-react-serverless-contact-form';
import ContactForm from '../ContactForm';

import InfrastructureConfig from '../../data/infrastructure'

const Footer = (props) => {
  const { content, bgImage } = props;
  const layoutName = "1JiktKjiPZyxSZGAyDUWS8";
  const { layouts } = content.data;
  
  const layout = layouts[layoutName];

  const infra = new InfrastructureConfig().getOutputs()

  return (
    <footer id="footer" className="footer layer-overlay overlay-dark-9">
      <CMSWrapper {...layout}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <ContactForm header="Enquiry" anchor="contact" recaptchaSiteKey={infra.recaptchaSiteKey} contactFormApiUrl={infra.contact_form_api_url}/>
          </div>
        </div>
      </div>
      <div className="container pt-70 pb-40">
        <div className="row border-bottom-black">
          <div className="col-sm-6 col-md-3">
            <div className="widget dark center"> 
            <h5 className="widget-title mb-10">Call Us</h5>
              <ul className="list-inline mt-5">
                <li className="m-0 pl-10 pr-10"> <i className="fa fa-phone text-theme-colored mr-5"></i> <a className="text-gray" href={`tel:{layout.primaryPhone}`}>{layout.primaryPhone}</a> </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="widget dark center"> 
            <h5 className="widget-title mb-10">Email Us</h5>
              <ul className="list-inline mt-5">
                <li className="m-0 pl-10 pr-10"> <i className="fa fa-envelope-o text-theme-colored mr-5"></i> <a className="text-gray" href={`mailto:{layout.primaryEmail}`}>{layout.primaryEmail}</a> </li>
              </ul>            
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="widget dark">
              <h5 className="widget-title mb-10">Connect With Us</h5>
              <ul className="styled-icons icon-dark icon-circled icon-sm">
                {layout.socialLinks ? Object.keys(layout.socialLinks).map(key => (
                  <li key={key}><a href={layout.socialLinks[key]}><i className={`fa fa-${key}`}></i></a></li>
                )) : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom bg-black-333">
        <div className="container pt-20 pb-20">
          <div className="row">
            <div className="col-md-6">
              <p className="font-11 text-black-777 m-0">Copyright &copy;2019 Rhee Tdk. All Rights Reserved</p>
            </div>
            <div className="col-md-6 text-right flip">
             {/* Nothing here atm */}
            </div>
          </div>
        </div>
      </div>
      </CMSWrapper>
    </footer>
  );
};


export default Footer;
