import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styled from 'react-emotion';
import { Link } from 'gatsby';

import ContentfulElement from '../ich/contentful-element';

const TopBarWrapper = styled.div`
`;

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.handlePlusClick = this.handlePlusClick.bind(this);
    this.state = { style: { top: -1000 } };
  }

  componentDidMount() {
    if (window) {
      window.addEventListener('load', this.handleLoad.bind(this));
      window.addEventListener('resize', this.handleLoad.bind(this));
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('load', this.handleLoad.bind(this));
      window.removeEventListener('resize', this.handleLoad.bind(this));
    }
  }

  handleLoad() {
    if (this.topBarWrapper) {
      const top = this.topBarWrapper.clientHeight * -1;
      this.setState({ style: { top } });
    }
  }

  handlePlusClick() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  render() {
    const topBarClass = classNames();
    return (
      <header id="header" className="header">
        <div className="header-nav navbar-fixed-top header-dark navbar-white navbar-transparent bg-transparent-1 navbar-sticky-animated animated-active">
          <div className="header-nav-wrapper">
            <div className="container">
              <nav id="menuzord-right" className="menuzord default no-bg">
                <Link className="menuzord-brand pull-left flip" to="/">
                  <div className="logo">
                    <img
                      src={this.props.contentful.data.layouts['1JiktKjiPZyxSZGAyDUWS8'].logo.size({
                        format: 'png',
                        width: 150,
                      })}
                      className="logo-img"
                      alt="Logo"
                    />
                  </div>
                </Link>
                <ul className="menuzord-menu pull-right">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/#locations">Locations</Link>
                  </li>
                  <li>
                    <Link to="/#gallery">Gallery</Link>
                  </li>
                  <li>
                    <Link to="#contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/#news-events-blogs">Events, News and Blog</Link>
                  </li>
                  <li>
                    <Link to="/#about">About Rhee Taekwondo</Link>
                  </li>
                  <li>
                    <Link to="/interview">World Master Rhee Interview</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  contentful: state.contentful,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopBar);
