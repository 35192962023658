/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';
import styled from 'react-emotion';

import { SubmitForm, LoadData } from './actions/submitForm';

import Row from '../ich/react-common-elements/Row';
import Section from '../ich/react-common-elements/Section';
import FormElements from '../Form';

import { required, email } from '../Form/Validators/common';

import Captcha from './captcha';

const {
  Form, TextArea, TextBox, ValidationWarning, Button, 
} = FormElements;

/* <script type="text/javascript">

  function updateAgreementsFromSelect(src)
  {
    updateAgreements(src.value);
  }

  function updateAgreements(src)
  {
    var divName = "." + src + "AggreementWrap";

    var d = $(".StrataAggreementWrap").hide();
    var d = $(".TorrensAggreementWrap").hide();
    var d = $(".UnknownAggreementWrap").hide();
    $(divName).show();
  }
</script>

<script type="text/javascript">

  function configureFormSelect(src)
  {
    configureForm(src.value);
  }

  function configureForm(src)
  {
    if(src == "Client")
    {
      $("#clientAgree").show();
      $("#nonClientAgrees").hide();
    }
    else
    {
      $("#clientAgree").hide();
      $("#nonClientAgrees").show();
    }
  }
</script>

*/

const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  & div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }
  & div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
    margin: -3px 0 0 -3px;
  }
  & div:nth-child(1) {
    animation-delay: -0.036s;
  }
  & div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  & div:nth-child(2) {
    animation-delay: -0.072s;
  }
  & div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  & div:nth-child(3) {
    animation-delay: -0.108s;
  }
  & div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .& div:nth-child(4) {
    animation-delay: -0.144s;
  }
  & div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  & div:nth-child(5) {
    animation-delay: -0.18s;
  }
  & div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  & div:nth-child(6) {
    animation-delay: -0.216s;
  }
  & div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  & div:nth-child(7) {
    animation-delay: -0.252s;
  }
  & div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  & div:nth-child(8) {
    animation-delay: -0.288s;
  }
  & div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const FormWrapper = styled.div`
position: relative;

& label {
  color: rgba(41,44,49,0.4);
  font-size: 1.2em;
}
`;

const SubmittingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgb(10,10,10,0.6);
  color: white;

  & div.wrapper {
    position: relative;
    display: block;
    top: 35%;
  }
`;

const SubmittedOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgb(10,10,10,0.6);
  color: white;

  & div.wrapper {
    position: relative;
    display: block;
    top: 35%;
  }  
`;

const FormHeader = styled.header`
  & h3 { color: white; }
`;

const validators = {
  name: [required],
  email: [required, email],
  subject: [required],
  message: [required],
  captcharesponse: [required]
};

const validate = (values) => {
  const errors = {};
  Object.keys(values).forEach((key) => {
    if (validators[key]) {
      errors.key = validators[key].reduce((a, b) => (a || b(values[key])), '');
    }
  });
  return errors;
};

class ContactForm extends Component {
  constructor(props, state) {
    super(props, state);
    /* TODO: Wire up inital state https://redux-form.com/6.7.0/examples/initializeFromState */
    this.submitForm = this.submitForm.bind(this);

    const initalFormState = {};
    const { load } = this.props;
    load(initalFormState);
  }

  submitForm() {
    const { valid, contactFormApiUrl, dispatch } = this.props;
    if (valid) {
      dispatch(SubmitForm(contactFormApiUrl));
    } else {
      console.log('not valid');
    }
  }


  render() {
    const {
      anchor,
      detailed = false,
      // valid,
      // products,
      // conveyancers,
      handleSubmit,
      // pristine,
      // reset,
      submitting,
      submitText = 'Submit',
      header = '',
      thankyouMessage = 'Thank you for your enquiry',
      contactForm,
      recaptchaSiteKey,
      contactFormApiUrl
    } = this.props;
    return (
      <div className="mt60 mb60">
        {anchor ? <a name={anchor} /> : null }
        <Form 
          onSubmit={handleSubmit}
          submitting={contactForm && contactForm.submitting}
          submitted={contactForm && contactForm.submitted}
          submitSucceed={contactForm && contactForm.submitted}
          submitFailed={contactForm && contactForm.error}
        >
          <FormWrapper id="ContactFormv2Container">
            {contactForm && contactForm.submitting
              ? (
                <SubmittingOverlay>
                  <div className="wrapper">
                    <Spinner>
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                    </Spinner>
                    <br />
Please wait

                  </div>

                </SubmittingOverlay>
              ) : null}
            {contactForm && contactForm.submitted
              ? <SubmittedOverlay><div className="wrapper">{thankyouMessage}</div></SubmittedOverlay>
              : null}
            {contactForm && contactForm.error
              ? <SubmittedOverlay><div className="wrapper">{contactForm.error}</div></SubmittedOverlay>
              : null}
            <div className="body">
              <ValidationWarning>
                <FormHeader><h3 className="title-uppercased large">{header}</h3></FormHeader>
                <fieldset>
                  <Row className="row">
                    <Section NoContainer className="col col-6">
                      <Field component={TextBox} validate={validators.name} name="name" label="Your Name" tabIndex="-1000" appendIcon>
                        {' '}
                        {/* onChange="updateAgreementsFromSelect(this)" */}
                      </Field>
                    </Section>
                    <Section NoContainer className="col col-6">
                      <Field component={TextBox} validate={validators.email} name="email" label="Your Email" tabIndex="-999" appendIcon>
                        {' '}
                        {/* onChange="configureFormSelect(this)" */}
                      </Field>
                    </Section>
                  </Row>
                  <Row className="row">
                    <Section NoContainer className="col col-12">
                      <Field component={TextBox} validate={validators.phone} name="phone" label="Your Phone" tabIndex="-998">
                        {' '}
                        { /* Load Booking Type dynamicily */ }
                      </Field>
                    </Section>
                  </Row>
                  {detailed
                    ? (
                      <div>
                        <Row className="row">
                          <Section NoContainer className="col col-12">
                            <Field component={TextArea} placeholder="one per line - Window in Lounge: widthxheight type: bifold" validate={validators.messurement} name="messurements" label="Messurements" rows="10" tabIndex="-996" />
                            <a href="#easy">see our measurement guide</a>
                          </Section>
                        </Row>
                        <Row className="row">
                          <Section NoContainer className="col col-12">
                            <Field component={TextBox} placeholder="3" validate={validators.numberOfWindows} name="numberOfWindows" label="Number of windows/doors" tabIndex="-995" />
                          </Section>
                        </Row>
                        <Row className="row">
                          <Section NoContainer className="col col-12">
                            <Field component={TextArea} validate={validators.installAddress} name="installAddress" label="Installation Address" tabIndex="-994" />
                          </Section>
                        </Row>
                        <Row className="row">
                          <Section NoContainer className="col col-12">
                            <Field component={TextArea} validate={validators.message} name="message" label="Other details" rows="3" tabIndex="-995" />
                          </Section>
                        </Row>
                      </div>
                    )
                    : (
                      <div>
                        <Row className="row">
                          <Section NoContainer className="col col-12">
                            <Field component={TextBox} validate={validators.subject} name="subject" label="Message Subject" tabIndex="-998">
                              {' '}
                              { /* Load Booking Type dynamicily */ }
                            </Field>
                          </Section>
                        </Row>
                        <Row className="row">
                          <Section NoContainer className="col col-12">
                            <Field component={TextArea} validate={validators.message} name="message" label="Message" rows="3" tabIndex="-997" />
                          </Section>
                        </Row>
                      </div>
                    )}
                </fieldset>
                <footer>
                  <Row className="row">
                  <Section NoContainer className="col col-12">
                    <Field name='captcharesponse' validate={validators.captcharesponse} component={Captcha} recaptchaSiteKey={recaptchaSiteKey}/>
                  </Section>
                  </Row>
                  <Row className="row">
                  <Section NoContainer className="col col-12">
                  
                    <Button type="button" onClick={this.submitForm} className="btn-d" name="bookingSubmit" disabled={submitting}>{submitText}</Button>
                  </Section>
                  </Row>
                </footer>
              </ValidationWarning>
            </div>
          </FormWrapper>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  contactForm: state.contactForm,
  initialValues: state.contactForm.initialState, // pull initial values from account reducer
});

const mapDispatchToProps = dispatch => ({
  load: bindActionCreators(LoadData, dispatch),
});

const ContactFormForm = reduxForm({
  form: 'contactForm', // a unique identifier for this form
  validate,
})(ContactForm);

const ContactFormFormConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactFormForm);

export default ContactFormFormConnect;
