import { ACTION_UPDATE_DATA, ACTION_UPDATING_DATA } from './constants';
import ContentfulLoader from '.';

const location = require('../customTypes/location');
const instructor = require('../customTypes/instructor');
const newsType = require('../customTypes/news');
const eventType = require('../customTypes/event');

export const ON_UPDATE_DATA = data => ({ type: ACTION_UPDATE_DATA, payload: data });

export const updateData = () => (dispatch) => {
  dispatch({ type: ACTION_UPDATING_DATA });
  const contentful = new ContentfulLoader([location, instructor, newsType, eventType]);
  return contentful.refreshData().then((data) => {
    dispatch({
      type: ACTION_UPDATE_DATA,
      data: contentful.generateState(data),
    });
  });
};
